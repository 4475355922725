/// font
$font-family-default: 'IBM Plex Sans', arial, sans-serif;

/// color
$background-color: #e8e8e8;
$background-color-header: #45494f;
$background-color-dev: rgb(26 182 44 / 81%);
$background-color-qa: #aa9720;
$background-color-demo: #f5ad42;
$background-color-prod: #b8392e;
$background-color-footer: #6a6d72;
$background-color-vms: #fbfbfb;

$color-primary: #11699c;
$color-primary-opacity: rgb(17 105 156 / 16%);
$color-primary-destructive: #931621;
$color-primary-destructive-hover: #e02232;
$color-tertiary-hover: #1a9ce8;
$color-grey: #f5f5f5;
$color-black: #000;
$color-white: #fff;
$color-light-grey: #c3c3c3;
$color-footer: #6a6d72;
$color-alert: #ff220c;
$color-success: #00a96c;
$color-blue: #11699c;
$color-light-green: #d8f0d5;
$color-light-red: #f2dede;
$color-dark-red: #810e0e;
$color-button-disabled: #88b4cd;
$color-orange: #ff8c00;
$color-yellow: #ebcb00;
$color-not-filled: #fffcea;

$color-text-primary: #45494f;
$color-text-light: #434c56;
$color-text-disabled: #7e8996;
$color-placeholder: #bbb;
$color-placeholder: #bbb;

$color-grey-icon: #45494f;
$color-light-grey-icon: #d3d3d3;

///Toast alert colors
$color-toast-info: #33b5e5;
$color-toast-success: #00c851;
$color-toast-warning: #f80;
$color-toast-danger: #c00;


/// border
$color-border-light: #e2e2e2;
$color-border-red: #ff220c;
$color-border-shadow: rgb(69 73 79 / 18%);

/// sizing
$component-min-width: 320px;
$navbar-height: 48px;
$navbar-small-height: 39px;
$footer-height: 48px;

// for offer-small.component and quick-search.component
$card-expiring-width-lg: 320px;
$card-expiring-width-xl: 280px;

// offering, offer-favorites

$link-color: #125e8a;
$color-border-sidebar-toggle: rgb(67 76 86 / 16%);
$color-shadow-sidebar-toggle: rgb(69 73 79 / 13%);
$color-grey-bullet-points: #d8d8d8;
$color-button-up: rgb(69 73 79 / 13%);

// offer, favorite

$image-width-xxs: 120px;
$image-width-xs: 120px;
$image-width-sm: 159px;
$image-width-md: 159px;
$image-width-lg: 224px;
$image-width-xl: 224px;
$image-height-xxs: 85px;
$image-height-xs: 90px;
$image-height-sm: 180px;
$image-height-md: 130px;
$image-height-lg: 170px;
$image-height-xl: 170px;

$footer-size-small: 48px;
$footer-size-big: 56px;
$footer-size-bigger: 96px;

$color-id: #6a6d72;
$color-box-item: #e2e2e2;

$color-gross-net: rgb(69 73 79 / 80%);

$color-redlight: #b80c1b;
$color-greenlight: #28a745;

// spinner
$color-spinner-layover: rgb(256 256 256 / 70%);
