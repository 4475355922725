@import './variables';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins/breakpoints';

.btn-primary {
  height: 32px;
  line-height: 1.3;
  background-color: $color-primary;
  border: 1px solid $color-primary;
  color: $color-white;
  font-weight: 600;
  font-size: $font-size-14;

  &:hover {
    color: $color-white;
    background-color: $color-primary;
  }
}

.btn-secondary {
  height: 32px;
  line-height: 1.3;
  background-color: $color-white;
  color: $color-primary;
  border: 1px solid $color-primary;
  font-weight: 600;
  font-size: $font-size-14;

  &:hover {
    background-color: $color-white;
    color: $color-primary;
  }

  &:disabled {
    background-color: $color-white !important;
    color: $color-primary !important;
    border: 1px solid $color-primary !important;
    opacity: 0.5;
    cursor: default;
  }
}

.btn-tertiary {
  color: $color-primary;
  font-weight: 700;
  background-color: transparent;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  height: 32px;

  &:hover {
    color: $color-tertiary-hover;
  }
}

.btn-tertiary:hover .tertiary-btn-icon {
  fill: $color-tertiary-hover;
}

.btn-tertiary-link {
  color: $color-primary;
  background-color: transparent;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  height: 32px;

  &:hover {
    color: $color-tertiary-hover;
    text-decoration-line: underline;
    text-underline-offset: 3px;
  }
}

.btn-tertiary-link:hover .tertiary-btn-icon {
  color: $color-tertiary-hover;
  fill: $color-tertiary-hover;
  text-decoration-line: underline;
  text-underline-offset: 3px;
}

.btn-primary-destructive {
  height: 32px;
  line-height: 1.3;
  background-color: $color-primary-destructive;
  border: 1px solid $color-primary-destructive;
  color: $color-white;
  font-weight: 600;
  font-size: $font-size-14;

  &:hover {
    color: $color-white;
    background-color: $color-primary-destructive-hover;
  }
}

.modal-content {
  border-radius: 6px;
}

.modal-header {
  background-color: $color-text-primary;
  color: $color-white;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  .close {
    color: $color-white;
    opacity: 1;
    text-shadow: none;
  }
}

a {
  color: $color-primary;
}

.error, {
  color: $color-alert;
}

ucs-price-input.ng-invalid {
  &.ng-touched,
  &.ng-dirty {
    input {
      border-left: 5px solid $color-alert;
      border-right: 1px solid $color-alert;
      border-top: 1px solid $color-alert;
      border-bottom: 1px solid $color-alert;
    }
  }
}

.ng-invalid:not(form):not(ucs-price-input):not(mat-form-field.upload-input) {
  &.ng-touched,
  &.ng-dirty {
    border-left: 5px solid $color-alert;
    border-right: 1px solid $color-alert;
    border-top: 1px solid $color-alert;
    border-bottom: 1px solid $color-alert;
  }
}

mat-form-field {
  &.ng-invalid:not(form) {
    border-left: inherit;
    border-right: inherit;
    border-top: inherit;
    border-bottom: inherit;
  }

  mat-select {
    border: 0;

    &.ng-invalid:not(form) {
      border: 0;
    }
  }
}

.font-small {
  color: $color-text-primary;
  font-size: $font-size-12;
  font-weight: 300;
  line-height: 14px;
}

.font-regular {
  color: $color-text-primary;
  font-size: $font-size-14;
  font-weight: 400;
  line-height: 17px;

  &.price {
    font-weight: 600;
  }
}

.font-large {
  color: $color-text-primary;
  font-size: $font-size-24;
  font-weight: 600;
  line-height: 32px;
}

.cdk-drag-preview {
  z-index: 9999999 !important;
}

input {
  &[type='checkbox'],
  &[type='radio'] {
    display: none;

    & + label::before {
      width: 24px;
      height: 24px;
      display: inline-block;
      vertical-align: middle;
      content: '';
    }

    &:checked + label::before {
      width: 24px;
      height: 24px;
      display: inline-block;
      vertical-align: middle;
      content: '';
    }

    &[type='checkbox'] {
      & + label::before {
        background-image: url('../assets/icons/square_outline_blue.svg');
      }

      &:checked + label::before {
        background-image: url('../assets/icons/square_checked_blue.svg');
      }
    }

    &[type='radio'] {
      & + label::before {
        background-image: url('../assets/icons/radio_blue.svg');
      }

      &:checked + label::before {
        background-image: url('../assets/icons/radio_selected_blue.svg');
      }

      &:disabled {
        & + label::before {
          background-image: url('../assets/icons/radio_grey.svg');
        }

        &:checked + label::before {
          background-image: url('../assets/icons/radio_selected_grey.svg');
        }
      }
    }
  }
}

label {
  margin-bottom: 0;
  cursor: pointer;
}

.w-85 {
  width: 85%;
}

.font-weight-bold {
  font-weight: 600;
}

.font-weight-medium {
  font-weight: 400;
}


.cursor-pointer {
  cursor: pointer;
}

.pagination {
  .active > .page-link {
    background-color: $color-primary;
    border-color: $color-primary;
    color: $color-white;
  }

  .page-link {
    color: $color-primary;
  }
}

.not-filled {
  background-color: $color-not-filled;
}

::ng-deep .ng-select.not-selected .ng-select-container {
  background-color: $color-not-filled;
}

.small-carousel-gallery-wrapper {
  background-color: $color-black;
  display: flex;
  height: $image-height-sm;
  max-height: $image-height-sm;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  z-index: 10;

  @include media-breakpoint-up(md) {
    width: $image-width-md;
    height: $image-height-md;
    max-height: $image-height-md;
  }

  @include media-breakpoint-up(lg) {
    width: $image-width-lg;
    height: $image-height-lg;
    max-height: $image-height-lg;
  }

  @include media-breakpoint-up(xl) {
    width: $image-width-xl;
    height: $image-height-xl;
    max-height: $image-height-xl;
  }

  ks-carousel {
    margin: auto;
    max-height: inherit;

    main,
    figure,
    picture,
    img {
      max-height: inherit !important;
      object-fit: fill !important;
    }
  }
}

.box-item {
  border: 1px solid $color-box-item;
  border-radius: 10px;
  min-height: 30px;
  line-height: 2.2rem;
  font-weight: 400;
  text-align: center;
  font-size: $font-size-14;

  @include media-breakpoint-down(md) {
    font-size: 12px;
    line-height: 33px;
  }
}

.vms-ui-element {
  min-width: fit-content;
  width: 145px;
  margin: 1%;
}

button.vms-ui-element {
  font-size: 14px;
  line-height: 1.5;
}

summary {
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  font-weight: bold;
}

summary::after {
  content: '';
  padding: 10px 10px 10px 40px;
  background: url('../../src/assets/icons/angle_down.svg') no-repeat 14px 50%;
}

details[open] > summary::after {
  background: url('../../src/assets/icons/angle_up.svg') no-repeat 14px 50%;
}


::ng-deep .info-popover-size {
  @media (min-width: 576px) {
    width: fit-content;
    word-break: break-word;
    max-width: 21vw !important;
  }

  @media (max-width: 575px) {
    width: fit-content;
    word-break: break-word;
    max-width: 51vw !important;
  }
}

::ng-deep .info-popover-auto-width {
  width: fit-content;
  max-width: none !important;
}
