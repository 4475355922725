@use '@angular/material' as mat;
@use 'ngx-float-ui/scss/theme-white' as floatUiWhiteTheme;
/* stylelint-disable no-invalid-position-at-import-rule */

@import 'scss/variables';
@import 'scss/defaults';
@import '@ng-select/ng-select/themes/default.theme.css';

@include mat.elevation-classes();
@include mat.app-background();

/* stylelint-disable function-no-unknown, function-parentheses-newline-inside */
$my-theme: mat.define-theme((
  color: (
    theme-type: light,
    primary: mat.$azure-palette,
  )
));
/* stylelint-enable function-no-unknown, function-parentheses-newline-inside */

html {
  @include mat.all-component-themes($my-theme);
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: normal;
  src: url('./assets/fonts/IBMPlexSans-Regular.woff') format('woff');
}

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  src: url('./assets/fonts/IBMPlexSans-SemiBold.woff') format('woff');
}

@font-face {
  font-family: 'IBM Plex Sans';
  src: url('./assets/fonts/IBMPlexSans-Light.woff') format('woff');
  font-weight: 300;
}

.ucs-header {
  background-color: $background-color-header;
}

.dev {
  background-color: $background-color-dev;
}

.qa {
  background-color: $background-color-qa;
}

.demo {
  background-color: $background-color-qa;
}

.prod {
  background-color: $background-color-prod;
}

.stage-info {
  position: fixed;
  right: 50px;
  top: 10%;
  padding: 12px;
  border-radius: 15px;
  text-align: center;
  align-items: center;
  height: 65px;
}

footer {
  background-color: $background-color-footer;
}

body {
  font-family: 'IBM Plex Sans', Arial, sans-serif; // use IBM Plex Sans, Fallback is Arial
  background-color: $background-color;
  letter-spacing: 0;
}

.cdk-overlay-container {
  z-index: 9999 !important;
}

.countdown-component,
.countdown-component-last-minutes {
  bs-tooltip-container {
    will-change: transform !important;
    top: 0 !important;
    left: 0 !important;

    .arrow {
      left: 84px !important;
    }

    @media (min-width: 771px) {
      transform: translate3d(40px, -35px, 0) !important;
    }

    @media (max-width: 770px) {
      transform: translate3d(-16px, -15px, 0) !important;
    }
  }

  &.is-bid-dialog-style {
    bs-tooltip-container {
      @media (min-width: 768px) {
        transform: translate3d(30px, 510px, 0) !important;
      }

      @media (max-width: 767px) and (min-width: 576px) {
        transform: translate3d(0, 565px, 0) !important;
      }

      @media (max-width: 575px) and (min-width: 560px) {
        transform: translate3d(0, 570px, 0) !important;
      }

      @media (max-width: 559px) and (min-width: 510px) {
        transform: translate3d(0, 590px, 0) !important;
      }

      @media (max-width: 509px) {
        transform: translate3d(0, 640px, 0) !important;
      }
    }
  }

  &.is-offer-detail-style {
    bs-tooltip-container {
      @media (min-width: 1200px) {
        transform: translate3d(130px, -30px, 0) !important;
      }

      @media (max-width: 1199px) and (min-width: 771px) {
        transform: translate3d(40px, -30px, 0) !important;
      }

      @media (max-width: 770px) {
        transform: translate3d(-10px, -20px, 0) !important;
      }
    }
  }

  &.is-external-dealer-bid-dialog {
    bs-tooltip-container {
      transform: translate3d(30px, 490px, 0) !important;
    }
  }

  &.is-offer-style {
    bs-tooltip-container {
      @media (min-width: 1200px) {
        transform: translate3d(130px, -30px, 0) !important;
      }

      @media (max-width: 1199px) and (min-width: 771px) {
        transform: translate3d(40px, -30px, 0) !important;
      }

      @media (max-width: 770px) {
        transform: translate3d(-10px, 20px, 0) !important;
      }
    }
  }
}

a.nav-link:not([href]),
a.nav-link:not([href]):hover {
  color: inherit;
  text-decoration: none;
}

.horizontal-select > .multiselect-label-row {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

ngb-popover-window.bs-popover-bottom-right > .arrow {
  right: 1.05em !important;
}

.ngxp__container.ngxp__animation {
  animation: none !important;
}

.cdk-overlay-container ks-modal-gallery {
  background-color: rgba(0 0 0 / 70%);
}

